import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright">
            <p>© { ( new Date().getFullYear() ) } Just a simple website to show off our journey together <a href="https://www.analogdigital.ca"> Made with love.</a></p>
        </div>
    );
};

export default Copyright;
