import React from 'react';

import Gallery from '../gallery/Gallery';

const HomePortfolio = () => {
    return (
        <section id="porfolio" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>A sample of our journey</h2>
                </div>

                <div className="description max-width-2">
                    <p>Check it out! </p>
                </div>

                <div className="spacer p-top-lg">
                    <Gallery />
                </div>
            </div>
        </section>
    );
};

export default HomePortfolio;
