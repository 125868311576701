import React from 'react';

const AboutContent = () => {
    return (
        <div id="about" className="block spacer p-top-xl">
            <div className="title">
                <h2>About us</h2>
            </div>

            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5> </h5>
                </div>

                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>I don't know what to say! We are just a boy and a girl who met and grew together. One day I guess we turned into Man and Women but I'm still not sure when where or how that happened.
                        One of my favourite things about our story is that it just happened. It wasn't perfect, but really what is? The perfect part was the love that we share! 
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutContent;
