import React from 'react';

const PageTitle404 = () => {
    return (
        <section id="page-title">
            <div className="title">
                <h1>404</h1>
            </div>
        </section>
    );
};

export default PageTitle404;
