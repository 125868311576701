import React from 'react';

const PageTitleAbout = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">About.</h1>
                </div>

                <div className="description-2">
                    <h3> This is a site I made for my beautiful wife. So whenever, wherever she can remember the special day we had! </h3>
                    <p>
                    <br/>
                    <br/>
                    Well I found out yesterday<br/>
                    That I'm in love<br/>
                    And I don't know what else to say<br/>
                    But thank you Lord<br/>
                    For that sundress on that Saturday<br/>
                    Walkin' barefoot down the beach<br/>
                    Smell of the rain up in the air<br/>
                    Smell the flowers in her hair<br/>
                    And I swear on my life<br/>
                    If you need a joke? I'll be there<br/>
                    You need a smoke? Here take my spare<br/>
                    I always thought that you were fine<br/>
                    So whataya say when we're 26<br/>
                    We'll get married just for kicks and<br/>
                    Move out to Alaska way up there<br/>
                    I'll get a job stacking bricks<br/>
                    You stay at home with the kids and<br/>
                    I'll bring the bacon back home to you girl<br/>
                    We're making the best of this world<br/>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default PageTitleAbout;
